import store from '@/store';
import router from '@/router';
import config from '@/config/firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { getDatabase, ref, child, get } from 'firebase/database';
import { useToast } from 'vue-toastification';
import i18n from '@/plugin/i18n';
export const fireApp = initializeApp(config);
export const fireAuth = getAuth();
export const fireStore = getDatabase();
const onAuthStateChangedPromise = new Promise((resolve, reject) => {
    onAuthStateChanged(fireAuth, async (user) => {
        await store.dispatch('fetchUser', user);
        resolve(user);
    }, (err) => {
        reject(err);
    });
});
export async function register(email, password) {
    try {
        const user = await createUserWithEmailAndPassword(fireAuth, email, password);
        await store.dispatch('fetchUser', user.user);
        console.log('Register in');
        useToast().info(i18n.global.t('pages.signin.signinToast'));
    }
    catch (error) {
        console.log(error);
    }
}
export async function login(email, password) {
    try {
        const user = await signInWithEmailAndPassword(fireAuth, email, password);
        await store.dispatch('fetchUser', user.user);
        console.log('Logged in');
        useToast().info(i18n.global.t('pages.signin.loginToast'));
    }
    catch (error) {
        console.log(error);
    }
}
export async function logout() {
    try {
        await signOut(fireAuth);
        console.log('Logged out');
        useToast().info(i18n.global.t('pages.signin.logoutToast'));
        router.push('/');
    }
    catch (error) {
        console.log(error);
    }
}
export async function getToken() {
    return await fireAuth.currentUser
        ?.getIdToken(true)
        .then((idToken) => {
        return idToken;
    })
        .catch((error) => {
        console.log(error);
    });
}
export async function getData(path) {
    const dbRef = ref(fireStore);
    return await get(child(dbRef, path))
        .then((snapshot) => {
        return snapshot.exists() ? snapshot.val() : null;
    })
        .catch((error) => {
        console.error(error);
    });
}
export async function resetPassword(email) {
    sendPasswordResetEmail(fireAuth, email)
        .then(() => {
        useToast().info("Ti abbiamo inviato un'email per reimpostare la password");
    })
        .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        useToast().error("Non è stato possibile inviare l'email per reimpostare la password");
    });
}
export const onAuthStateInit = () => onAuthStateChangedPromise;
