const crimes = {
    state: {
        crimes: [],
        filters: {}
    },
    mutations: {
        SET_CRIMES(state, crimes) {
            state.crimes = crimes;
        },
        SET_FILTERS(state, filters) {
            state.filters = filters;
        }
    },
    actions: {
        setCrimes({ commit }, crimes) {
            commit('SET_CRIMES', crimes);
        },
        setFilters({ commit }, filters) {
            commit('SET_FILTERS', filters);
        }
    },
    getters: {
        crimes: (state) => state.crimes,
        filters: (state) => state.filters
    }
};
export default crimes;
