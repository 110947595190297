import { createStore } from 'vuex';
import auth from './auth';
import crimes from './crimes';
import search from './search';
export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        crimes,
        search
    }
});
