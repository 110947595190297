/* eslint-disable @typescript-eslint/ban-ts-comment */
export const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            title: 'MineCrime',
            sitemap: {
                priority: 1
            }
        },
        //@ts-ignore
        props: (route) => ({ search: route.query.search })
    },
    {
        path: '/search',
        redirect: '/search/map',
        meta: {
            sitemap: {
                priority: 1
            }
        }
    },
    {
        path: '/search/:section(map|charts|report)',
        name: 'Search',
        component: () => import('../views/Search/Search.vue'),
        meta: {
            hideNavbar: true,
            title: 'Cerca con MineCrime',
            sitemap: {
                slugs: ['map', 'charts', 'report']
            }
        },
        //@ts-ignore
        props: (route) => ({
            place: route.query.place,
            start: route.query.start,
            end: route.query.end,
            section: route.params.section
        })
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/User/User.vue'),
        redirect: '/user/login',
        children: [
            {
                path: 'login',
                component: () => import('../views/User/Login.vue'),
                meta: {
                    title: 'Accedi - MineCrime',
                    requiresAuth: false
                }
            },
            {
                path: 'register',
                component: () => import('../views/User/Register.vue'),
                meta: {
                    title: 'Registrati - MineCrime',
                    requiresAuth: false
                }
            },
            {
                path: 'dashboard',
                component: () => import('../views/User/Dashboard.vue'),
                meta: {
                    title: 'Il tuo profilo - MineCrime',
                    requiresAuth: true
                }
            },
            {
                path: 'risk',
                component: () => import('../views/User/Risk.vue'),
                meta: {
                    title: 'Risk Modeling - MineCrime',
                    requiresAuth: true,
                    requiresRisk: true
                }
            }
        ]
    },
    {
        path: '/projects/taxiblu',
        name: 'Projects',
        component: () => import('../views/Projects/Taxiblu.vue'),
        meta: {
            title: 'Taxi Sicuro x Taxiblu - MineCrime'
        }
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import('../views/Products.vue'),
        meta: {
            title: 'I nostri servizi - MineCrime'
        }
    },
    /* {
      path: '/about',
      name: 'About',
      component: () => import('../views/About.vue'),
      meta: {
        title: 'Ecco chi siamo - MineCrime'
      }
    }, */
    {
        path: '/company',
        name: 'Company',
        component: () => import('../views/Legal/Company.vue'),
        meta: {
            title: 'La nostra azienda - MineCrime'
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: {
            title: 'Contattaci - MineCrime'
        }
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('../views/Legal/Privacy.vue'),
        meta: {
            title: 'Privacy policy - MineCrime'
        }
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import('../views/Legal/Terms.vue'),
        meta: {
            title: 'Termini e condizioni - MineCrime'
        }
    },
    // otherwise redirect to home
    { path: '/:catchAll(.*)', redirect: '/' }
];
