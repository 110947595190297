import store from '@/store';
import { routes } from './routes';
import { onAuthStateInit } from '@/api/firebase';
import { createRouter, createWebHistory } from 'vue-router';
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash)
            return { el: to.hash };
        return { top: 0 };
    }
});
router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth !== undefined)
        await onAuthStateInit();
    if (to.meta.requiresAuth == true && !store.getters.isLogged)
        next({ path: '/user/login', query: { redirect: to.fullPath } });
    else if (to.meta.requiresAuth == false && store.getters.isLogged)
        next('/user/dashboard');
    else if (to.meta.requiresRisk == true &&
        !store.getters.user?.risks?.available)
        next('/user/dashboard');
    else
        next();
    if (to.meta.title !== undefined)
        document.title = String(to.meta.title);
    else
        document.title = 'MineCrime';
});
export default router;
