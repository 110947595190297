const firebaseConfig = {
    apiKey: 'AIzaSyCIhLiem46qPrwpWDqXPah43atNGPRFkJk',
    authDomain: 'mine-crime.firebaseapp.com',
    databaseURL: 'https://mine-crime-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'mine-crime',
    storageBucket: 'mine-crime.appspot.com',
    messagingSenderId: '670787528941',
    appId: '1:670787528941:web:0c62b814c8f43d10ac04e4',
    measurementId: 'G-1H5Z8KCCG9'
};
export default firebaseConfig;
