import { getToken, getData } from '@/api/firebase';
const auth = {
    state: {
        user: {
            loggedIn: false,
            data: null,
            payer: false
        }
    },
    mutations: {
        SET_USER_STATE(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
            if (data && data.lastPayment) {
                const date = new Date(data.lastPayment.expiration);
                const today = new Date();
                if (date > today)
                    state.user.payer = true;
                else
                    state.user.payer = false;
            }
            else
                state.user.payer = false;
        }
    },
    actions: {
        async fetchUser({ commit }, user) {
            commit('SET_USER_STATE', user !== null);
            if (user) {
                const token = await getToken();
                const data = await getData('Users/' + user.uid);
                commit('SET_USER', {
                    name: data.name,
                    risks: data.risks,
                    email: user.email,
                    uid: user.uid,
                    lastPayment: data.lastPayment,
                    token: token
                });
            }
            else {
                commit('SET_USER', null);
            }
        }
    },
    getters: {
        user: (state) => state.user.data,
        isLogged: (state) => state.user.loggedIn,
        isPayer: (state) => state.user.payer
    }
};
export default auth;
