const search = {
    state: {
        place: null,
        activePlace: null,
        target: {},
        activeFocus: null,
        showFilters: false,
        enableDraw: false
    },
    mutations: {
        SET_PLACE(state, place) {
            state.place = place;
        },
        SET_ACTIVE_PLACE(state, place) {
            state.activePlace = place;
        },
        SET_TARGET(state, target) {
            state.target = target;
        },
        SET_ACTIVE_FOCUS(state, focus) {
            state.activeFocus = focus;
        },
        SET_SHOW_FILTERS(state, show) {
            state.showFilters = show;
        },
        SET_ENABLE_DRAW(state, enable) {
            state.enableDraw = enable;
        }
    },
    actions: {
        setPlace({ commit }, place) {
            commit('SET_PLACE', place);
        },
        setActivePlace({ commit }, place) {
            commit('SET_ACTIVE_PLACE', place);
        },
        setTarget({ commit }, target) {
            commit('SET_TARGET', target);
        },
        setActiveFocus({ commit }, focus) {
            commit('SET_ACTIVE_FOCUS', focus);
        },
        showFilters({ commit }) {
            commit('SET_SHOW_FILTERS', true);
        },
        hideFilters({ commit }) {
            commit('SET_SHOW_FILTERS', false);
        },
        enableDraw({ commit }) {
            commit('SET_ENABLE_DRAW', true);
            commit('SET_ACTIVE_FOCUS', null);
        },
        disableDraw({ commit }) {
            commit('SET_ENABLE_DRAW', false);
        }
    },
    getters: {
        place: (state) => state.place,
        activePlace: (state) => state.activePlace,
        target: (state) => state.target,
        activeFocus: (state) => state.activeFocus,
        showFilters: (state) => state.showFilters,
        enableDraw: (state) => state.enableDraw
    }
};
export default search;
