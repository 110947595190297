import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import it from './locales/it.json';
const i18n = createI18n({
    locale: localStorage.getItem('locale') || navigator.language.split('-')[0] || 'en',
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: true,
    messages: {
        it,
        en
    }
});
export default i18n;
