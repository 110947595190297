import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Menu = _resolveComponent("Menu");
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!_ctx.$route.meta.hideNavbar)
            ? (_openBlock(), _createBlock(_component_Menu, { key: 0 }))
            : _createCommentVNode("", true),
        _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
                (_openBlock(), _createBlock(_KeepAlive, { include: "Search" }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ], 1024))
            ]),
            _: 1
        })
    ], 64));
}
